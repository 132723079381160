import { defineNuxtPlugin } from '#imports'
import { getStoreModules } from '@application/store'
import { createStore } from 'vuex'
import type { IRootState } from '@application/store/state.interface'
import type { NuxtApp } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const store = createStore({
    modules: getStoreModules()
  })
  nuxtApp.vueApp.use(store)
  nuxtApp.provide('store', store)

  if (process.server) {
    // eslint-disable-next-line no-param-reassign
    nuxtApp.payload.vuex = JSON.parse(JSON.stringify(store.state))
  } else if (nuxtApp.isHydrating && nuxtApp.payload && nuxtApp.payload.vuex) {
    store.replaceState(nuxtApp.payload.vuex as IRootState)
  }
})
