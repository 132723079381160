<template>
  <div class="page-my-account">
    <div class="page-my-account__content">
      <div class="menu-and-contact-us">
        <div class="menu-and-contact-us__hello-menu">
          <ens-my-account-hello :name="currentUser.firstName" :cardNumber="cardNumber" data-test-my-account-hello />
          <ens-my-account-menu :is-mobile-view="isMobile" data-test-my-account-menu />
        </div>
        <div v-if="!isMobile" class="menu-and-contact-us__contact-us">
          <ens-contact-us data-test-contact-us />
        </div>
      </div>
      <div class="content">
        <NuxtPage :currentUser="currentUser" />
      </div>
      <div v-if="isMobile" class="contact-us-mobile">
        <ens-contact-us data-test-contact-us />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { useBreakpoints } from '@application/composables/breakpoints/useBreakpoints'
import { EnsContactUs } from '@contexts/contact-us'
import { EnsMyAccountHello, EnsMyAccountMenu } from '@contexts/my-account'
import {
  computed,
  defineComponent,
  definePageMeta,
  navigateTo,
  onMounted,
  useNuxtApp,
  useHead,
  useLocalePath
} from '#imports'
import type { RouteLocationNormalized } from 'vue-router'

definePageMeta({
  middleware: [
    'router-auth',
    (to: RouteLocationNormalized) => {
      const localePath = useLocalePath()
      const myAccountPath = localePath({ name: 'my-account' })
      if (to.path === myAccountPath || to.path === `${myAccountPath}/`)
        return navigateTo({ path: localePath({ name: 'my-account-my-orders' }) }, { replace: true })
      return true
    }
  ]
})

export default defineComponent({
  name: 'ens-my-account-page',
  components: {
    EnsContactUs,
    EnsMyAccountHello,
    EnsMyAccountMenu
  },
  setup() {
    const { $store: store } = useNuxtApp()
    const currentUser = computed(() => store.state.user.current)
    const { isMobile } = useBreakpoints()
    const cardNumber = computed(() => currentUser.value?.loyalty?.cardNumber || null)

    onMounted(() => store.dispatch('user/setCurrentUser'))
    useHead(() => ({
      bodyAttrs: {
        /* Class attribute should not be used, as it can be overwritten by other components. */
        'data-has-header-with-no-margin': 'true'
      }
    }))
    return {
      currentUser,
      cardNumber,
      isMobile
    }
  }
})
</script>

<style lang="scss" scoped>
.page-my-account {
  background-image: url(assets-brand/my-account/banner.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 15rem;

  @include idf-media-up(md) {
    display: flex;
    background-size: 100% 24rem;
  }

  &__content {
    width: 100%;

    @include idf-media-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10rem;

      @include idf-container('xl');
    }

    .menu-and-contact-us {
      @include idf-media-up(md) {
        margin-right: var(--idf-spacing-5);
      }

      &__hello-menu {
        padding-top: var(--idf-spacing-6);

        @include idf-media-up(md) {
          padding: var(--idf-spacing-3);
          background-color: var(--idf-color-neutral-100);
          border: var(--idf-border-width-1) solid var(--idf-color-neutral-2-100);
          box-shadow: var(--idf-shadow-light);
        }
      }

      &__contact-us {
        margin-top: var(--idf-spacing-4);
        background-color: var(--idf-color-neutral-2-100);
      }

      &__hello-menu,
      &__contact-us {
        @include idf-media-up(md) {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          width: 32rem;
          margin-bottom: var(--idf-spacing-4);
          padding: var(--idf-spacing-4);
          border-radius: var(--idf-border-radius-m);
        }
      }
    }

    .content {
      @include idf-media-up(md) {
        width: calc(100% - 32rem - var(--idf-spacing-2) * 3 - var(--idf-spacing-3));
      }

      @include idf-media-up(xl) {
        width: 79rem;
      }
    }

    .contact-us-mobile {
      margin: var(--idf-spacing-5) var(--idf-spacing-2) var(--idf-spacing-4) var(--idf-spacing-2);
      padding: var(--idf-spacing-2) var(--idf-spacing-3) var(--idf-spacing-3) var(--idf-spacing-3);
      background-color: var(--idf-color-neutral-2-100);
      border-radius: var(--idf-border-radius-m);
    }
  }
}
</style>
