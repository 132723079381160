import type { IConfigState } from '@application/store/config/interface'
import type { IRootState } from '@application/store/state.interface'
import type { ActionContext, ActionTree } from 'vuex'
import { getConfig } from '@contexts/config'
import logger from '@/utils/logger/logger'
import { configTypes } from './constants'

const configActions: ActionTree<IConfigState, IRootState> = {
  async initConfig(context: ActionContext<IConfigState, IRootState>, locale: string) {
    const config = await getConfig.execute(locale).catch((e) => {
      logger.error(e, 'Error fetching config')
      return null
    })
    context.commit(configTypes.SET_CONFIG, config)

    return context.state
  }
}

export default configActions
